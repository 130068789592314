import cobruLogo from '../assets/icons/cobruIconHorizontal.png'
import ezyLogo from '../assets/icons/EzyLogo.jpeg'
import pagaHoyLogo from '../assets/icons/paga-hoy-horizontal.png'
import tucanpayLogo from '../assets/icons/tucanpay-logo.png'

export const countries_new = [
    {
      "id": 0,
      "code": "",
      "flag": "",
      "indicative": "",
      "countryName": 0,
      "value": "",
      "label": "Escoge tu país",
      "label2": " +"
    },
    {
      "id": 1,
      "code": "AF",
      "flag": "🇦🇫",
      "indicative": 93,
      "countryName": "Afghanistan",
      "value": "AF",
      "label": "🇦🇫 +93 - Afghanistan",
      "label2": "🇦🇫 +93"
    },
    {
      "id": 2,
      "code": "AL",
      "flag": "🇦🇱",
      "indicative": 355,
      "countryName": "Albania",
      "value": "AL",
      "label": "🇦🇱 +355 - Albania",
      "label2": "🇦🇱 +355"
    },
    {
      "id": 3,
      "code": "DZ",
      "flag": "🇩🇿",
      "indicative": 213,
      "countryName": "Algeria",
      "value": "DZ",
      "label": "🇩🇿 +213 - Algeria",
      "label2": "🇩🇿 +213"
    },
    {
      "id": 4,
      "code": "AS",
      "flag": "🇦🇸",
      "indicative": 1684,
      "countryName": "American Samoa",
      "value": "AS",
      "label": "🇦🇸 +1684 - American Samoa",
      "label2": "🇦🇸 +1684"
    },
    {
      "id": 5,
      "code": "AD",
      "flag": "🇦🇩",
      "indicative": 376,
      "countryName": "Andorra",
      "value": "AD",
      "label": "🇦🇩 +376 - Andorra",
      "label2": "🇦🇩 +376"
    },
    {
      "id": 6,
      "code": "AO",
      "flag": "🇦🇴",
      "indicative": 244,
      "countryName": "Angola",
      "value": "AO",
      "label": "🇦🇴 +244 - Angola",
      "label2": "🇦🇴 +244"
    },
    {
      "id": 7,
      "code": "AI",
      "flag": "🇦🇮",
      "indicative": 1264,
      "countryName": "Anguilla",
      "value": "AI",
      "label": "🇦🇮 +1264 - Anguilla",
      "label2": "🇦🇮 +1264"
    },
    {
      "id": 8,
      "code": "AQ",
      "flag": "🇦🇶",
      "indicative": 0,
      "countryName": "Antarctica",
      "value": "AQ",
      "label": "🇦🇶 +0 - Antarctica",
      "label2": "🇦🇶 +0"
    },
    {
      "id": 9,
      "code": "AR",
      "flag": "🇦🇷",
      "indicative": 54,
      "countryName": "Argentina",
      "value": "AR",
      "label": "🇦🇷 +54 - Argentina",
      "label2": "🇦🇷 +54"
    },
    {
      "id": 10,
      "code": "AM",
      "flag": "🇦🇲",
      "indicative": 374,
      "countryName": "Armenia",
      "value": "AM",
      "label": "🇦🇲 +374 - Armenia",
      "label2": "🇦🇲 +374"
    },
    {
      "id": 11,
      "code": "AW",
      "flag": "🇦🇼",
      "indicative": 297,
      "countryName": "Aruba",
      "value": "AW",
      "label": "🇦🇼 +297 - Aruba",
      "label2": "🇦🇼 +297"
    },
    {
      "id": 12,
      "code": "AU",
      "flag": "🇦🇺",
      "indicative": 61,
      "countryName": "Australia",
      "value": "AU",
      "label": "🇦🇺 +61 - Australia",
      "label2": "🇦🇺 +61"
    },
    {
      "id": 13,
      "code": "AT",
      "flag": "🇦🇹",
      "indicative": 43,
      "countryName": "Austria",
      "value": "AT",
      "label": "🇦🇹 +43 - Austria",
      "label2": "🇦🇹 +43"
    },
    {
      "id": 14,
      "code": "AZ",
      "flag": "🇦🇿",
      "indicative": 994,
      "countryName": "Azerbaijan",
      "value": "AZ",
      "label": "🇦🇿 +994 - Azerbaijan",
      "label2": "🇦🇿 +994"
    },
    {
      "id": 15,
      "code": "BH",
      "flag": "🇧🇭",
      "indicative": 973,
      "countryName": "Bahrain",
      "value": "BH",
      "label": "🇧🇭 +973 - Bahrain",
      "label2": "🇧🇭 +973"
    },
    {
      "id": 16,
      "code": "BD",
      "flag": "🇧🇩",
      "indicative": 880,
      "countryName": "Bangladesh",
      "value": "BD",
      "label": "🇧🇩 +880 - Bangladesh",
      "label2": "🇧🇩 +880"
    },
    {
      "id": 17,
      "code": "BB",
      "flag": "🇧🇧",
      "indicative": 1246,
      "countryName": "Barbados",
      "value": "BB",
      "label": "🇧🇧 +1246 - Barbados",
      "label2": "🇧🇧 +1246"
    },
    {
      "id": 18,
      "code": "BY",
      "flag": "🇧🇾",
      "indicative": 375,
      "countryName": "Belarus",
      "value": "BY",
      "label": "🇧🇾 +375 - Belarus",
      "label2": "🇧🇾 +375"
    },
    {
      "id": 19,
      "code": "BE",
      "flag": "🇧🇪",
      "indicative": 32,
      "countryName": "Belgium",
      "value": "BE",
      "label": "🇧🇪 +32 - Belgium",
      "label2": "🇧🇪 +32"
    },
    {
      "id": 20,
      "code": "BZ",
      "flag": "🇧🇿",
      "indicative": 501,
      "countryName": "Belize",
      "value": "BZ",
      "label": "🇧🇿 +501 - Belize",
      "label2": "🇧🇿 +501"
    },
    {
      "id": 21,
      "code": "BJ",
      "flag": "🇧🇯",
      "indicative": 229,
      "countryName": "Benin",
      "value": "BJ",
      "label": "🇧🇯 +229 - Benin",
      "label2": "🇧🇯 +229"
    },
    {
      "id": 22,
      "code": "BM",
      "flag": "🇧🇲",
      "indicative": 1441,
      "countryName": "Bermuda",
      "value": "BM",
      "label": "🇧🇲 +1441 - Bermuda",
      "label2": "🇧🇲 +1441"
    },
    {
      "id": 23,
      "code": "BT",
      "flag": "🇧🇹",
      "indicative": 975,
      "countryName": "Bhutan",
      "value": "BT",
      "label": "🇧🇹 +975 - Bhutan",
      "label2": "🇧🇹 +975"
    },
    {
      "id": 24,
      "code": "BO",
      "flag": "🇧🇴",
      "indicative": 591,
      "countryName": "Bolivia",
      "value": "BO",
      "label": "🇧🇴 +591 - Bolivia",
      "label2": "🇧🇴 +591"
    },
    {
      "id": 25,
      "code": "BW",
      "flag": "🇧🇼",
      "indicative": 267,
      "countryName": "Botswana",
      "value": "BW",
      "label": "🇧🇼 +267 - Botswana",
      "label2": "🇧🇼 +267"
    },
    {
      "id": 26,
      "code": "BV",
      "flag": "🇧🇻",
      "indicative": 0,
      "countryName": "Bouvet Island",
      "value": "BV",
      "label": "🇧🇻 +0 - Bouvet Island",
      "label2": "🇧🇻 +0"
    },
    {
      "id": 27,
      "code": "BR",
      "flag": "🇧🇷",
      "indicative": 55,
      "countryName": "Brazil",
      "value": "BR",
      "label": "🇧🇷 +55 - Brazil",
      "label2": "🇧🇷 +55"
    },
    {
      "id": 28,
      "code": "IO",
      "flag": "🇮🇴",
      "indicative": 246,
      "countryName": "British Indian Ocean Territory",
      "value": "IO",
      "label": "🇮🇴 +246 - British Indian Ocean Territory",
      "label2": "🇮🇴 +246"
    },
    {
      "id": 29,
      "code": "BN",
      "flag": "🇧🇳",
      "indicative": 673,
      "countryName": "Brunei",
      "value": "BN",
      "label": "🇧🇳 +673 - Brunei",
      "label2": "🇧🇳 +673"
    },
    {
      "id": 30,
      "code": "BG",
      "flag": "🇧🇬",
      "indicative": 359,
      "countryName": "Bulgaria",
      "value": "BG",
      "label": "🇧🇬 +359 - Bulgaria",
      "label2": "🇧🇬 +359"
    },
    {
      "id": 31,
      "code": "BF",
      "flag": "🇧🇫",
      "indicative": 226,
      "countryName": "Burkina Faso",
      "value": "BF",
      "label": "🇧🇫 +226 - Burkina Faso",
      "label2": "🇧🇫 +226"
    },
    {
      "id": 32,
      "code": "BI",
      "flag": "🇧🇮",
      "indicative": 257,
      "countryName": "Burundi",
      "value": "BI",
      "label": "🇧🇮 +257 - Burundi",
      "label2": "🇧🇮 +257"
    },
    {
      "id": 33,
      "code": "KH",
      "flag": "🇰🇭",
      "indicative": 855,
      "countryName": "Cambodia",
      "value": "KH",
      "label": "🇰🇭 +855 - Cambodia",
      "label2": "🇰🇭 +855"
    },
    {
      "id": 34,
      "code": "CM",
      "flag": "🇨🇲",
      "indicative": 237,
      "countryName": "Cameroon",
      "value": "CM",
      "label": "🇨🇲 +237 - Cameroon",
      "label2": "🇨🇲 +237"
    },
    {
      "id": 35,
      "code": "CA",
      "flag": "🇨🇦",
      "indicative": 1,
      "countryName": "Canada",
      "value": "CA",
      "label": "🇨🇦 +1 - Canada",
      "label2": "🇨🇦 +1"
    },
    {
      "id": 36,
      "code": "CV",
      "flag": "🇨🇻",
      "indicative": 238,
      "countryName": "Cape Verde",
      "value": "CV",
      "label": "🇨🇻 +238 - Cape Verde",
      "label2": "🇨🇻 +238"
    },
    {
      "id": 37,
      "code": "KY",
      "flag": "🇰🇾",
      "indicative": 1345,
      "countryName": "Cayman Islands",
      "value": "KY",
      "label": "🇰🇾 +1345 - Cayman Islands",
      "label2": "🇰🇾 +1345"
    },
    {
      "id": 38,
      "code": "CF",
      "flag": "🇨🇫",
      "indicative": 236,
      "countryName": "Central African R.",
      "value": "CF",
      "label": "🇨🇫 +236 - Central African R.",
      "label2": "🇨🇫 +236"
    },
    {
      "id": 39,
      "code": "TD",
      "flag": "🇹🇩",
      "indicative": 235,
      "countryName": "Chad",
      "value": "TD",
      "label": "🇹🇩 +235 - Chad",
      "label2": "🇹🇩 +235"
    },
    {
      "id": 40,
      "code": "CL",
      "flag": "🇨🇱",
      "indicative": 56,
      "countryName": "Chile",
      "value": "CL",
      "label": "🇨🇱 +56 - Chile",
      "label2": "🇨🇱 +56"
    },
    {
      "id": 41,
      "code": "CN",
      "flag": "🇨🇳",
      "indicative": 86,
      "countryName": "China",
      "value": "CN",
      "label": "🇨🇳 +86 - China",
      "label2": "🇨🇳 +86"
    },
    {
      "id": 42,
      "code": "CX",
      "flag": "🇨🇽",
      "indicative": 61,
      "countryName": "Christmas Island",
      "value": "CX",
      "label": "🇨🇽 +61 - Christmas Island",
      "label2": "🇨🇽 +61"
    },
    {
      "id": 43,
      "code": "CC",
      "flag": "🇨🇨",
      "indicative": 672,
      "countryName": "Cocos (Keeling) Islands",
      "value": "CC",
      "label": "🇨🇨 +672 - Cocos (Keeling) Islands",
      "label2": "🇨🇨 +672"
    },
    {
      "id": 44,
      "code": "CO",
      "flag": "🇨🇴",
      "indicative": 57,
      "countryName": "Colombia",
      "value": "CO",
      "label": "🇨🇴 +57 - Colombia",
      "label2": "🇨🇴 +57"
    },
    {
      "id": 45,
      "code": "KM",
      "flag": "🇰🇲",
      "indicative": 269,
      "countryName": "Comoros",
      "value": "KM",
      "label": "🇰🇲 +269 - Comoros",
      "label2": "🇰🇲 +269"
    },
    {
      "id": 46,
      "code": "CK",
      "flag": "🇨🇰",
      "indicative": 682,
      "countryName": "Cook Islands",
      "value": "CK",
      "label": "🇨🇰 +682 - Cook Islands",
      "label2": "🇨🇰 +682"
    },
    {
      "id": 47,
      "code": "CR",
      "flag": "🇨🇷",
      "indicative": 506,
      "countryName": "Costa Rica",
      "value": "CR",
      "label": "🇨🇷 +506 - Costa Rica",
      "label2": "🇨🇷 +506"
    },
    {
      "id": 48,
      "code": "CU",
      "flag": "🇨🇺",
      "indicative": 53,
      "countryName": "Cuba",
      "value": "CU",
      "label": "🇨🇺 +53 - Cuba",
      "label2": "🇨🇺 +53"
    },
    {
      "id": 49,
      "code": "CY",
      "flag": "🇨🇾",
      "indicative": 357,
      "countryName": "Cyprus",
      "value": "CY",
      "label": "🇨🇾 +357 - Cyprus",
      "label2": "🇨🇾 +357"
    },
    {
      "id": 50,
      "code": "DK",
      "flag": "🇩🇰",
      "indicative": 45,
      "countryName": "Denmark",
      "value": "DK",
      "label": "🇩🇰 +45 - Denmark",
      "label2": "🇩🇰 +45"
    },
    {
      "id": 51,
      "code": "DJ",
      "flag": "🇩🇯",
      "indicative": 253,
      "countryName": "Djibouti",
      "value": "DJ",
      "label": "🇩🇯 +253 - Djibouti",
      "label2": "🇩🇯 +253"
    },
    {
      "id": 52,
      "code": "DM",
      "flag": "🇩🇲",
      "indicative": 1767,
      "countryName": "Dominica",
      "value": "DM",
      "label": "🇩🇲 +1767 - Dominica",
      "label2": "🇩🇲 +1767"
    },
    {
      "id": 53,
      "code": "DO",
      "flag": "🇩🇴",
      "indicative": 1809,
      "countryName": "Dominican Republic",
      "value": "DO",
      "label": "🇩🇴 +1809 - Dominican Republic",
      "label2": "🇩🇴 +1809"
    },
    {
      "id": 54,
      "code": "EC",
      "flag": "🇪🇨",
      "indicative": 593,
      "countryName": "Ecuador",
      "value": "EC",
      "label": "🇪🇨 +593 - Ecuador",
      "label2": "🇪🇨 +593"
    },
    {
      "id": 55,
      "code": "EG",
      "flag": "🇪🇬",
      "indicative": 20,
      "countryName": "Egypt",
      "value": "EG",
      "label": "🇪🇬 +20 - Egypt",
      "label2": "🇪🇬 +20"
    },
    {
      "id": 56,
      "code": "SV",
      "flag": "🇸🇻",
      "indicative": 503,
      "countryName": "El Salvador",
      "value": "SV",
      "label": "🇸🇻 +503 - El Salvador",
      "label2": "🇸🇻 +503"
    },
    {
      "id": 57,
      "code": "GQ",
      "flag": "🇬🇶",
      "indicative": 240,
      "countryName": "Equatorial Guinea",
      "value": "GQ",
      "label": "🇬🇶 +240 - Equatorial Guinea",
      "label2": "🇬🇶 +240"
    },
    {
      "id": 58,
      "code": "ER",
      "flag": "🇪🇷",
      "indicative": 291,
      "countryName": "Eritrea",
      "value": "ER",
      "label": "🇪🇷 +291 - Eritrea",
      "label2": "🇪🇷 +291"
    },
    {
      "id": 59,
      "code": "EE",
      "flag": "🇪🇪",
      "indicative": 372,
      "countryName": "Estonia",
      "value": "EE",
      "label": "🇪🇪 +372 - Estonia",
      "label2": "🇪🇪 +372"
    },
    {
      "id": 60,
      "code": "ET",
      "flag": "🇪🇹",
      "indicative": 251,
      "countryName": "Ethiopia",
      "value": "ET",
      "label": "🇪🇹 +251 - Ethiopia",
      "label2": "🇪🇹 +251"
    },
    {
      "id": 61,
      "code": "FK",
      "flag": "🇫🇰",
      "indicative": 500,
      "countryName": "Falkland Islands",
      "value": "FK",
      "label": "🇫🇰 +500 - Falkland Islands",
      "label2": "🇫🇰 +500"
    },
    {
      "id": 62,
      "code": "FO",
      "flag": "🇫🇴",
      "indicative": 298,
      "countryName": "Faroe Islands",
      "value": "FO",
      "label": "🇫🇴 +298 - Faroe Islands",
      "label2": "🇫🇴 +298"
    },
    {
      "id": 63,
      "code": "FI",
      "flag": "🇫🇮",
      "indicative": 358,
      "countryName": "Finland",
      "value": "FI",
      "label": "🇫🇮 +358 - Finland",
      "label2": "🇫🇮 +358"
    },
    {
      "id": 64,
      "code": "FR",
      "flag": "🇫🇷",
      "indicative": 33,
      "countryName": "France",
      "value": "FR",
      "label": "🇫🇷 +33 - France",
      "label2": "🇫🇷 +33"
    },
    {
      "id": 65,
      "code": "GF",
      "flag": "🇬🇫",
      "indicative": 594,
      "countryName": "French Guiana",
      "value": "GF",
      "label": "🇬🇫 +594 - French Guiana",
      "label2": "🇬🇫 +594"
    },
    {
      "id": 66,
      "code": "PF",
      "flag": "🇵🇫",
      "indicative": 689,
      "countryName": "French Polynesia",
      "value": "PF",
      "label": "🇵🇫 +689 - French Polynesia",
      "label2": "🇵🇫 +689"
    },
    {
      "id": 67,
      "code": "TF",
      "flag": "🇹🇫",
      "indicative": 0,
      "countryName": "French Southern T.",
      "value": "TF",
      "label": "🇹🇫 +0 - French Southern T.",
      "label2": "🇹🇫 +0"
    },
    {
      "id": 68,
      "code": "GA",
      "flag": "🇬🇦",
      "indicative": 241,
      "countryName": "Gabon",
      "value": "GA",
      "label": "🇬🇦 +241 - Gabon",
      "label2": "🇬🇦 +241"
    },
    {
      "id": 69,
      "code": "GE",
      "flag": "🇬🇪",
      "indicative": 995,
      "countryName": "Georgia",
      "value": "GE",
      "label": "🇬🇪 +995 - Georgia",
      "label2": "🇬🇪 +995"
    },
    {
      "id": 70,
      "code": "DE",
      "flag": "🇩🇪",
      "indicative": 49,
      "countryName": "Germany",
      "value": "DE",
      "label": "🇩🇪 +49 - Germany",
      "label2": "🇩🇪 +49"
    },
    {
      "id": 71,
      "code": "GH",
      "flag": "🇬🇭",
      "indicative": 233,
      "countryName": "Ghana",
      "value": "GH",
      "label": "🇬🇭 +233 - Ghana",
      "label2": "🇬🇭 +233"
    },
    {
      "id": 72,
      "code": "GI",
      "flag": "🇬🇮",
      "indicative": 350,
      "countryName": "Gibraltar",
      "value": "GI",
      "label": "🇬🇮 +350 - Gibraltar",
      "label2": "🇬🇮 +350"
    },
    {
      "id": 73,
      "code": "GR",
      "flag": "🇬🇷",
      "indicative": 30,
      "countryName": "Greece",
      "value": "GR",
      "label": "🇬🇷 +30 - Greece",
      "label2": "🇬🇷 +30"
    },
    {
      "id": 74,
      "code": "GL",
      "flag": "🇬🇱",
      "indicative": 299,
      "countryName": "Greenland",
      "value": "GL",
      "label": "🇬🇱 +299 - Greenland",
      "label2": "🇬🇱 +299"
    },
    {
      "id": 75,
      "code": "GD",
      "flag": "🇬🇩",
      "indicative": 1473,
      "countryName": "Grenada",
      "value": "GD",
      "label": "🇬🇩 +1473 - Grenada",
      "label2": "🇬🇩 +1473"
    },
    {
      "id": 76,
      "code": "GP",
      "flag": "🇬🇵",
      "indicative": 590,
      "countryName": "Guadeloupe",
      "value": "GP",
      "label": "🇬🇵 +590 - Guadeloupe",
      "label2": "🇬🇵 +590"
    },
    {
      "id": 77,
      "code": "GU",
      "flag": "🇬🇺",
      "indicative": 1671,
      "countryName": "Guam",
      "value": "GU",
      "label": "🇬🇺 +1671 - Guam",
      "label2": "🇬🇺 +1671"
    },
    {
      "id": 78,
      "code": "GT",
      "flag": "🇬🇹",
      "indicative": 502,
      "countryName": "Guatemala",
      "value": "GT",
      "label": "🇬🇹 +502 - Guatemala",
      "label2": "🇬🇹 +502"
    },
    {
      "id": 79,
      "code": "GN",
      "flag": "🇬🇳",
      "indicative": 224,
      "countryName": "Guinea",
      "value": "GN",
      "label": "🇬🇳 +224 - Guinea",
      "label2": "🇬🇳 +224"
    },
    {
      "id": 80,
      "code": "GW",
      "flag": "🇬🇼",
      "indicative": 245,
      "countryName": "Guinea-Bissau",
      "value": "GW",
      "label": "🇬🇼 +245 - Guinea-Bissau",
      "label2": "🇬🇼 +245"
    },
    {
      "id": 81,
      "code": "GY",
      "flag": "🇬🇾",
      "indicative": 592,
      "countryName": "Guyana",
      "value": "GY",
      "label": "🇬🇾 +592 - Guyana",
      "label2": "🇬🇾 +592"
    },
    {
      "id": 82,
      "code": "HT",
      "flag": "🇭🇹",
      "indicative": 509,
      "countryName": "Haiti",
      "value": "HT",
      "label": "🇭🇹 +509 - Haiti",
      "label2": "🇭🇹 +509"
    },
    {
      "id": 83,
      "code": "HN",
      "flag": "🇭🇳",
      "indicative": 504,
      "countryName": "Honduras",
      "value": "HN",
      "label": "🇭🇳 +504 - Honduras",
      "label2": "🇭🇳 +504"
    },
    {
      "id": 84,
      "code": "HU",
      "flag": "🇭🇺",
      "indicative": 36,
      "countryName": "Hungary",
      "value": "HU",
      "label": "🇭🇺 +36 - Hungary",
      "label2": "🇭🇺 +36"
    },
    {
      "id": 85,
      "code": "IS",
      "flag": "🇮🇸",
      "indicative": 354,
      "countryName": "Iceland",
      "value": "IS",
      "label": "🇮🇸 +354 - Iceland",
      "label2": "🇮🇸 +354"
    },
    {
      "id": 86,
      "code": "IN",
      "flag": "🇮🇳",
      "indicative": 91,
      "countryName": "India",
      "value": "IN",
      "label": "🇮🇳 +91 - India",
      "label2": "🇮🇳 +91"
    },
    {
      "id": 87,
      "code": "ID",
      "flag": "🇮🇩",
      "indicative": 62,
      "countryName": "Indonesia",
      "value": "ID",
      "label": "🇮🇩 +62 - Indonesia",
      "label2": "🇮🇩 +62"
    },
    {
      "id": 88,
      "code": "IR",
      "flag": "🇮🇷",
      "indicative": 98,
      "countryName": "Iran",
      "value": "IR",
      "label": "🇮🇷 +98 - Iran",
      "label2": "🇮🇷 +98"
    },
    {
      "id": 89,
      "code": "IQ",
      "flag": "🇮🇶",
      "indicative": 964,
      "countryName": "Iraq",
      "value": "IQ",
      "label": "🇮🇶 +964 - Iraq",
      "label2": "🇮🇶 +964"
    },
    {
      "id": 90,
      "code": "IE",
      "flag": "🇮🇪",
      "indicative": 353,
      "countryName": "Ireland",
      "value": "IE",
      "label": "🇮🇪 +353 - Ireland",
      "label2": "🇮🇪 +353"
    },
    {
      "id": 91,
      "code": "IL",
      "flag": "🇮🇱",
      "indicative": 972,
      "countryName": "Israel",
      "value": "IL",
      "label": "🇮🇱 +972 - Israel",
      "label2": "🇮🇱 +972"
    },
    {
      "id": 92,
      "code": "IT",
      "flag": "🇮🇹",
      "indicative": 39,
      "countryName": "Italy",
      "value": "IT",
      "label": "🇮🇹 +39 - Italy",
      "label2": "🇮🇹 +39"
    },
    {
      "id": 93,
      "code": "JM",
      "flag": "🇯🇲",
      "indicative": 1876,
      "countryName": "Jamaica",
      "value": "JM",
      "label": "🇯🇲 +1876 - Jamaica",
      "label2": "🇯🇲 +1876"
    },
    {
      "id": 94,
      "code": "JP",
      "flag": "🇯🇵",
      "indicative": 81,
      "countryName": "Japan",
      "value": "JP",
      "label": "🇯🇵 +81 - Japan",
      "label2": "🇯🇵 +81"
    },
    {
      "id": 95,
      "code": "JO",
      "flag": "🇯🇴",
      "indicative": 962,
      "countryName": "Jordan",
      "value": "JO",
      "label": "🇯🇴 +962 - Jordan",
      "label2": "🇯🇴 +962"
    },
    {
      "id": 96,
      "code": "KZ",
      "flag": "🇰🇿",
      "indicative": 7,
      "countryName": "Kazakhstan",
      "value": "KZ",
      "label": "🇰🇿 +7 - Kazakhstan",
      "label2": "🇰🇿 +7"
    },
    {
      "id": 97,
      "code": "KE",
      "flag": "🇰🇪",
      "indicative": 254,
      "countryName": "Kenya",
      "value": "KE",
      "label": "🇰🇪 +254 - Kenya",
      "label2": "🇰🇪 +254"
    },
    {
      "id": 98,
      "code": "KI",
      "flag": "🇰🇮",
      "indicative": 686,
      "countryName": "Kiribati",
      "value": "KI",
      "label": "🇰🇮 +686 - Kiribati",
      "label2": "🇰🇮 +686"
    },
    {
      "id": 99,
      "code": "KW",
      "flag": "🇰🇼",
      "indicative": 965,
      "countryName": "Kuwait",
      "value": "KW",
      "label": "🇰🇼 +965 - Kuwait",
      "label2": "🇰🇼 +965"
    },
    {
      "id": 100,
      "code": "KG",
      "flag": "🇰🇬",
      "indicative": 996,
      "countryName": "Kyrgyzstan",
      "value": "KG",
      "label": "🇰🇬 +996 - Kyrgyzstan",
      "label2": "🇰🇬 +996"
    },
    {
      "id": 101,
      "code": "LA",
      "flag": "🇱🇦",
      "indicative": 856,
      "countryName": "Laos",
      "value": "LA",
      "label": "🇱🇦 +856 - Laos",
      "label2": "🇱🇦 +856"
    },
    {
      "id": 102,
      "code": "LV",
      "flag": "🇱🇻",
      "indicative": 371,
      "countryName": "Latvia",
      "value": "LV",
      "label": "🇱🇻 +371 - Latvia",
      "label2": "🇱🇻 +371"
    },
    {
      "id": 103,
      "code": "LB",
      "flag": "🇱🇧",
      "indicative": 961,
      "countryName": "Lebanon",
      "value": "LB",
      "label": "🇱🇧 +961 - Lebanon",
      "label2": "🇱🇧 +961"
    },
    {
      "id": 104,
      "code": "LS",
      "flag": "🇱🇸",
      "indicative": 266,
      "countryName": "Lesotho",
      "value": "LS",
      "label": "🇱🇸 +266 - Lesotho",
      "label2": "🇱🇸 +266"
    },
    {
      "id": 105,
      "code": "LR",
      "flag": "🇱🇷",
      "indicative": 231,
      "countryName": "Liberia",
      "value": "LR",
      "label": "🇱🇷 +231 - Liberia",
      "label2": "🇱🇷 +231"
    },
    {
      "id": 106,
      "code": "LY",
      "flag": "🇱🇾",
      "indicative": 218,
      "countryName": "Libya",
      "value": "LY",
      "label": "🇱🇾 +218 - Libya",
      "label2": "🇱🇾 +218"
    },
    {
      "id": 107,
      "code": "LI",
      "flag": "🇱🇮",
      "indicative": 423,
      "countryName": "Liechtenstein",
      "value": "LI",
      "label": "🇱🇮 +423 - Liechtenstein",
      "label2": "🇱🇮 +423"
    },
    {
      "id": 108,
      "code": "LT",
      "flag": "🇱🇹",
      "indicative": 370,
      "countryName": "Lithuania",
      "value": "LT",
      "label": "🇱🇹 +370 - Lithuania",
      "label2": "🇱🇹 +370"
    },
    {
      "id": 109,
      "code": "LU",
      "flag": "🇱🇺",
      "indicative": 352,
      "countryName": "Luxembourg",
      "value": "LU",
      "label": "🇱🇺 +352 - Luxembourg",
      "label2": "🇱🇺 +352"
    },
    {
      "id": 110,
      "code": "MK",
      "flag": "🇲🇰",
      "indicative": 389,
      "countryName": "Macedonia",
      "value": "MK",
      "label": "🇲🇰 +389 - Macedonia",
      "label2": "🇲🇰 +389"
    },
    {
      "id": 111,
      "code": "MG",
      "flag": "🇲🇬",
      "indicative": 261,
      "countryName": "Madagascar",
      "value": "MG",
      "label": "🇲🇬 +261 - Madagascar",
      "label2": "🇲🇬 +261"
    },
    {
      "id": 112,
      "code": "MW",
      "flag": "🇲🇼",
      "indicative": 265,
      "countryName": "Malawi",
      "value": "MW",
      "label": "🇲🇼 +265 - Malawi",
      "label2": "🇲🇼 +265"
    },
    {
      "id": 113,
      "code": "MY",
      "flag": "🇲🇾",
      "indicative": 60,
      "countryName": "Malaysia",
      "value": "MY",
      "label": "🇲🇾 +60 - Malaysia",
      "label2": "🇲🇾 +60"
    },
    {
      "id": 114,
      "code": "MV",
      "flag": "🇲🇻",
      "indicative": 960,
      "countryName": "Maldives",
      "value": "MV",
      "label": "🇲🇻 +960 - Maldives",
      "label2": "🇲🇻 +960"
    },
    {
      "id": 115,
      "code": "ML",
      "flag": "🇲🇱",
      "indicative": 223,
      "countryName": "Mali",
      "value": "ML",
      "label": "🇲🇱 +223 - Mali",
      "label2": "🇲🇱 +223"
    },
    {
      "id": 116,
      "code": "MT",
      "flag": "🇲🇹",
      "indicative": 356,
      "countryName": "Malta",
      "value": "MT",
      "label": "🇲🇹 +356 - Malta",
      "label2": "🇲🇹 +356"
    },
    {
      "id": 117,
      "code": "MH",
      "flag": "🇲🇭",
      "indicative": 692,
      "countryName": "Marshall Islands",
      "value": "MH",
      "label": "🇲🇭 +692 - Marshall Islands",
      "label2": "🇲🇭 +692"
    },
    {
      "id": 118,
      "code": "MQ",
      "flag": "🇲🇶",
      "indicative": 596,
      "countryName": "Martinique",
      "value": "MQ",
      "label": "🇲🇶 +596 - Martinique",
      "label2": "🇲🇶 +596"
    },
    {
      "id": 119,
      "code": "MR",
      "flag": "🇲🇷",
      "indicative": 222,
      "countryName": "Mauritania",
      "value": "MR",
      "label": "🇲🇷 +222 - Mauritania",
      "label2": "🇲🇷 +222"
    },
    {
      "id": 120,
      "code": "MU",
      "flag": "🇲🇺",
      "indicative": 230,
      "countryName": "Mauritius",
      "value": "MU",
      "label": "🇲🇺 +230 - Mauritius",
      "label2": "🇲🇺 +230"
    },
    {
      "id": 121,
      "code": "YT",
      "flag": "🇾🇹",
      "indicative": 269,
      "countryName": "Mayotte",
      "value": "YT",
      "label": "🇾🇹 +269 - Mayotte",
      "label2": "🇾🇹 +269"
    },
    {
      "id": 122,
      "code": "MX",
      "flag": "🇲🇽",
      "indicative": 52,
      "countryName": "Mexico",
      "value": "MX",
      "label": "🇲🇽 +52 - Mexico",
      "label2": "🇲🇽 +52"
    },
    {
      "id": 123,
      "code": "FM",
      "flag": "🇫🇲",
      "indicative": 691,
      "countryName": "Micronesia",
      "value": "FM",
      "label": "🇫🇲 +691 - Micronesia",
      "label2": "🇫🇲 +691"
    },
    {
      "id": 124,
      "code": "MD",
      "flag": "🇲🇩",
      "indicative": 373,
      "countryName": "Moldova",
      "value": "MD",
      "label": "🇲🇩 +373 - Moldova",
      "label2": "🇲🇩 +373"
    },
    {
      "id": 125,
      "code": "MC",
      "flag": "🇲🇨",
      "indicative": 377,
      "countryName": "Monaco",
      "value": "MC",
      "label": "🇲🇨 +377 - Monaco",
      "label2": "🇲🇨 +377"
    },
    {
      "id": 126,
      "code": "MN",
      "flag": "🇲🇳",
      "indicative": 976,
      "countryName": "Mongolia",
      "value": "MN",
      "label": "🇲🇳 +976 - Mongolia",
      "label2": "🇲🇳 +976"
    },
    {
      "id": 127,
      "code": "MS",
      "flag": "🇲🇸",
      "indicative": 1664,
      "countryName": "Montserrat",
      "value": "MS",
      "label": "🇲🇸 +1664 - Montserrat",
      "label2": "🇲🇸 +1664"
    },
    {
      "id": 128,
      "code": "MA",
      "flag": "🇲🇦",
      "indicative": 212,
      "countryName": "Morocco",
      "value": "MA",
      "label": "🇲🇦 +212 - Morocco",
      "label2": "🇲🇦 +212"
    },
    {
      "id": 129,
      "code": "MZ",
      "flag": "🇲🇿",
      "indicative": 258,
      "countryName": "Mozambique",
      "value": "MZ",
      "label": "🇲🇿 +258 - Mozambique",
      "label2": "🇲🇿 +258"
    },
    {
      "id": 130,
      "code": "NA",
      "flag": "🇳🇦",
      "indicative": 264,
      "countryName": "Namibia",
      "value": "NA",
      "label": "🇳🇦 +264 - Namibia",
      "label2": "🇳🇦 +264"
    },
    {
      "id": 131,
      "code": "NR",
      "flag": "🇳🇷",
      "indicative": 674,
      "countryName": "Nauru",
      "value": "NR",
      "label": "🇳🇷 +674 - Nauru",
      "label2": "🇳🇷 +674"
    },
    {
      "id": 132,
      "code": "NP",
      "flag": "🇳🇵",
      "indicative": 977,
      "countryName": "Nepal",
      "value": "NP",
      "label": "🇳🇵 +977 - Nepal",
      "label2": "🇳🇵 +977"
    },
    {
      "id": 133,
      "code": "NC",
      "flag": "🇳🇨",
      "indicative": 687,
      "countryName": "New Caledonia",
      "value": "NC",
      "label": "🇳🇨 +687 - New Caledonia",
      "label2": "🇳🇨 +687"
    },
    {
      "id": 134,
      "code": "NZ",
      "flag": "🇳🇿",
      "indicative": 64,
      "countryName": "New Zealand",
      "value": "NZ",
      "label": "🇳🇿 +64 - New Zealand",
      "label2": "🇳🇿 +64"
    },
    {
      "id": 135,
      "code": "NI",
      "flag": "🇳🇮",
      "indicative": 505,
      "countryName": "Nicaragua",
      "value": "NI",
      "label": "🇳🇮 +505 - Nicaragua",
      "label2": "🇳🇮 +505"
    },
    {
      "id": 136,
      "code": "NE",
      "flag": "🇳🇪",
      "indicative": 227,
      "countryName": "Niger",
      "value": "NE",
      "label": "🇳🇪 +227 - Niger",
      "label2": "🇳🇪 +227"
    },
    {
      "id": 137,
      "code": "NG",
      "flag": "🇳🇬",
      "indicative": 234,
      "countryName": "Nigeria",
      "value": "NG",
      "label": "🇳🇬 +234 - Nigeria",
      "label2": "🇳🇬 +234"
    },
    {
      "id": 138,
      "code": "NU",
      "flag": "🇳🇺",
      "indicative": 683,
      "countryName": "Niue",
      "value": "NU",
      "label": "🇳🇺 +683 - Niue",
      "label2": "🇳🇺 +683"
    },
    {
      "id": 139,
      "code": "NF",
      "flag": "🇳🇫",
      "indicative": 672,
      "countryName": "Norfolk Island",
      "value": "NF",
      "label": "🇳🇫 +672 - Norfolk Island",
      "label2": "🇳🇫 +672"
    },
    {
      "id": 140,
      "code": "MP",
      "flag": "🇲🇵",
      "indicative": 1670,
      "countryName": "Northern Mariana I.",
      "value": "MP",
      "label": "🇲🇵 +1670 - Northern Mariana I.",
      "label2": "🇲🇵 +1670"
    },
    {
      "id": 141,
      "code": "NO",
      "flag": "🇳🇴",
      "indicative": 47,
      "countryName": "Norway",
      "value": "NO",
      "label": "🇳🇴 +47 - Norway",
      "label2": "🇳🇴 +47"
    },
    {
      "id": 142,
      "code": "OM",
      "flag": "🇴🇲",
      "indicative": 968,
      "countryName": "Oman",
      "value": "OM",
      "label": "🇴🇲 +968 - Oman",
      "label2": "🇴🇲 +968"
    },
    {
      "id": 143,
      "code": "PK",
      "flag": "🇵🇰",
      "indicative": 92,
      "countryName": "Pakistan",
      "value": "PK",
      "label": "🇵🇰 +92 - Pakistan",
      "label2": "🇵🇰 +92"
    },
    {
      "id": 144,
      "code": "PW",
      "flag": "🇵🇼",
      "indicative": 680,
      "countryName": "Palau",
      "value": "PW",
      "label": "🇵🇼 +680 - Palau",
      "label2": "🇵🇼 +680"
    },
    {
      "id": 145,
      "code": "PA",
      "flag": "🇵🇦",
      "indicative": 507,
      "countryName": "Panama",
      "value": "PA",
      "label": "🇵🇦 +507 - Panama",
      "label2": "🇵🇦 +507"
    },
    {
      "id": 146,
      "code": "PY",
      "flag": "🇵🇾",
      "indicative": 595,
      "countryName": "Paraguay",
      "value": "PY",
      "label": "🇵🇾 +595 - Paraguay",
      "label2": "🇵🇾 +595"
    },
    {
      "id": 147,
      "code": "PE",
      "flag": "🇵🇪",
      "indicative": 51,
      "countryName": "Peru",
      "value": "PE",
      "label": "🇵🇪 +51 - Peru",
      "label2": "🇵🇪 +51"
    },
    {
      "id": 148,
      "code": "PH",
      "flag": "🇵🇭",
      "indicative": 63,
      "countryName": "Philippines",
      "value": "PH",
      "label": "🇵🇭 +63 - Philippines",
      "label2": "🇵🇭 +63"
    },
    {
      "id": 149,
      "code": "PL",
      "flag": "🇵🇱",
      "indicative": 48,
      "countryName": "Poland",
      "value": "PL",
      "label": "🇵🇱 +48 - Poland",
      "label2": "🇵🇱 +48"
    },
    {
      "id": 150,
      "code": "PT",
      "flag": "🇵🇹",
      "indicative": 351,
      "countryName": "Portugal",
      "value": "PT",
      "label": "🇵🇹 +351 - Portugal",
      "label2": "🇵🇹 +351"
    },
    {
      "id": 151,
      "code": "PR",
      "flag": "🇵🇷",
      "indicative": 1787,
      "countryName": "Puerto Rico",
      "value": "PR",
      "label": "🇵🇷 +1787 - Puerto Rico",
      "label2": "🇵🇷 +1787"
    },
    {
      "id": 152,
      "code": "QA",
      "flag": "🇶🇦",
      "indicative": 974,
      "countryName": "Qatar",
      "value": "QA",
      "label": "🇶🇦 +974 - Qatar",
      "label2": "🇶🇦 +974"
    },
    {
      "id": 153,
      "code": "RO",
      "flag": "🇷🇴",
      "indicative": 40,
      "countryName": "Romania",
      "value": "RO",
      "label": "🇷🇴 +40 - Romania",
      "label2": "🇷🇴 +40"
    },
    {
      "id": 154,
      "code": "RU",
      "flag": "🇷🇺",
      "indicative": 70,
      "countryName": "Russia",
      "value": "RU",
      "label": "🇷🇺 +70 - Russia",
      "label2": "🇷🇺 +70"
    },
    {
      "id": 155,
      "code": "RW",
      "flag": "🇷🇼",
      "indicative": 250,
      "countryName": "Rwanda",
      "value": "RW",
      "label": "🇷🇼 +250 - Rwanda",
      "label2": "🇷🇼 +250"
    },
    {
      "id": 156,
      "code": "WS",
      "flag": "🇼🇸",
      "indicative": 684,
      "countryName": "Samoa",
      "value": "WS",
      "label": "🇼🇸 +684 - Samoa",
      "label2": "🇼🇸 +684"
    },
    {
      "id": 157,
      "code": "SM",
      "flag": "🇸🇲",
      "indicative": 378,
      "countryName": "San Marino",
      "value": "SM",
      "label": "🇸🇲 +378 - San Marino",
      "label2": "🇸🇲 +378"
    },
    {
      "id": 158,
      "code": "SA",
      "flag": "🇸🇦",
      "indicative": 966,
      "countryName": "Saudi Arabia",
      "value": "SA",
      "label": "🇸🇦 +966 - Saudi Arabia",
      "label2": "🇸🇦 +966"
    },
    {
      "id": 159,
      "code": "SN",
      "flag": "🇸🇳",
      "indicative": 221,
      "countryName": "Senegal",
      "value": "SN",
      "label": "🇸🇳 +221 - Senegal",
      "label2": "🇸🇳 +221"
    },
    {
      "id": 160,
      "code": "RS",
      "flag": "🇷🇸",
      "indicative": 381,
      "countryName": "Serbia",
      "value": "RS",
      "label": "🇷🇸 +381 - Serbia",
      "label2": "🇷🇸 +381"
    },
    {
      "id": 161,
      "code": "SC",
      "flag": "🇸🇨",
      "indicative": 248,
      "countryName": "Seychelles",
      "value": "SC",
      "label": "🇸🇨 +248 - Seychelles",
      "label2": "🇸🇨 +248"
    },
    {
      "id": 162,
      "code": "SL",
      "flag": "🇸🇱",
      "indicative": 232,
      "countryName": "Sierra Leone",
      "value": "SL",
      "label": "🇸🇱 +232 - Sierra Leone",
      "label2": "🇸🇱 +232"
    },
    {
      "id": 163,
      "code": "SG",
      "flag": "🇸🇬",
      "indicative": 65,
      "countryName": "Singapore",
      "value": "SG",
      "label": "🇸🇬 +65 - Singapore",
      "label2": "🇸🇬 +65"
    },
    {
      "id": 164,
      "code": "SK",
      "flag": "🇸🇰",
      "indicative": 421,
      "countryName": "Slovakia",
      "value": "SK",
      "label": "🇸🇰 +421 - Slovakia",
      "label2": "🇸🇰 +421"
    },
    {
      "id": 165,
      "code": "SI",
      "flag": "🇸🇮",
      "indicative": 386,
      "countryName": "Slovenia",
      "value": "SI",
      "label": "🇸🇮 +386 - Slovenia",
      "label2": "🇸🇮 +386"
    },
    {
      "id": 166,
      "code": "SB",
      "flag": "🇸🇧",
      "indicative": 677,
      "countryName": "Solomon Islands",
      "value": "SB",
      "label": "🇸🇧 +677 - Solomon Islands",
      "label2": "🇸🇧 +677"
    },
    {
      "id": 167,
      "code": "SO",
      "flag": "🇸🇴",
      "indicative": 252,
      "countryName": "Somalia",
      "value": "SO",
      "label": "🇸🇴 +252 - Somalia",
      "label2": "🇸🇴 +252"
    },
    {
      "id": 168,
      "code": "ZA",
      "flag": "🇿🇦",
      "indicative": 27,
      "countryName": "South Africa",
      "value": "ZA",
      "label": "🇿🇦 +27 - South Africa",
      "label2": "🇿🇦 +27"
    },
    {
      "id": 169,
      "code": "SS",
      "flag": "🇸🇸",
      "indicative": 211,
      "countryName": "South Sudan",
      "value": "SS",
      "label": "🇸🇸 +211 - South Sudan",
      "label2": "🇸🇸 +211"
    },
    {
      "id": 170,
      "code": "ES",
      "flag": "🇪🇸",
      "indicative": 34,
      "countryName": "Spain",
      "value": "ES",
      "label": "🇪🇸 +34 - Spain",
      "label2": "🇪🇸 +34"
    },
    {
      "id": 171,
      "code": "LK",
      "flag": "🇱🇰",
      "indicative": 94,
      "countryName": "Sri Lanka",
      "value": "LK",
      "label": "🇱🇰 +94 - Sri Lanka",
      "label2": "🇱🇰 +94"
    },
    {
      "id": 172,
      "code": "SD",
      "flag": "🇸🇩",
      "indicative": 249,
      "countryName": "Sudan",
      "value": "SD",
      "label": "🇸🇩 +249 - Sudan",
      "label2": "🇸🇩 +249"
    },
    {
      "id": 173,
      "code": "SR",
      "flag": "🇸🇷",
      "indicative": 597,
      "countryName": "Suriname",
      "value": "SR",
      "label": "🇸🇷 +597 - Suriname",
      "label2": "🇸🇷 +597"
    },
    {
      "id": 174,
      "code": "SZ",
      "flag": "🇸🇿",
      "indicative": 268,
      "countryName": "Swaziland",
      "value": "SZ",
      "label": "🇸🇿 +268 - Swaziland",
      "label2": "🇸🇿 +268"
    },
    {
      "id": 175,
      "code": "SE",
      "flag": "🇸🇪",
      "indicative": 46,
      "countryName": "Sweden",
      "value": "SE",
      "label": "🇸🇪 +46 - Sweden",
      "label2": "🇸🇪 +46"
    },
    {
      "id": 176,
      "code": "CH",
      "flag": "🇨🇭",
      "indicative": 41,
      "countryName": "Switzerland",
      "value": "CH",
      "label": "🇨🇭 +41 - Switzerland",
      "label2": "🇨🇭 +41"
    },
    {
      "id": 177,
      "code": "SY",
      "flag": "🇸🇾",
      "indicative": 963,
      "countryName": "Syria",
      "value": "SY",
      "label": "🇸🇾 +963 - Syria",
      "label2": "🇸🇾 +963"
    },
    {
      "id": 178,
      "code": "TW",
      "flag": "🇹🇼",
      "indicative": 886,
      "countryName": "Taiwan",
      "value": "TW",
      "label": "🇹🇼 +886 - Taiwan",
      "label2": "🇹🇼 +886"
    },
    {
      "id": 179,
      "code": "TJ",
      "flag": "🇹🇯",
      "indicative": 992,
      "countryName": "Tajikistan",
      "value": "TJ",
      "label": "🇹🇯 +992 - Tajikistan",
      "label2": "🇹🇯 +992"
    },
    {
      "id": 180,
      "code": "TZ",
      "flag": "🇹🇿",
      "indicative": 255,
      "countryName": "Tanzania",
      "value": "TZ",
      "label": "🇹🇿 +255 - Tanzania",
      "label2": "🇹🇿 +255"
    },
    {
      "id": 181,
      "code": "TH",
      "flag": "🇹🇭",
      "indicative": 66,
      "countryName": "Thailand",
      "value": "TH",
      "label": "🇹🇭 +66 - Thailand",
      "label2": "🇹🇭 +66"
    },
    {
      "id": 182,
      "code": "TG",
      "flag": "🇹🇬",
      "indicative": 228,
      "countryName": "Togo",
      "value": "TG",
      "label": "🇹🇬 +228 - Togo",
      "label2": "🇹🇬 +228"
    },
    {
      "id": 183,
      "code": "TK",
      "flag": "🇹🇰",
      "indicative": 690,
      "countryName": "Tokelau",
      "value": "TK",
      "label": "🇹🇰 +690 - Tokelau",
      "label2": "🇹🇰 +690"
    },
    {
      "id": 184,
      "code": "TO",
      "flag": "🇹🇴",
      "indicative": 676,
      "countryName": "Tonga",
      "value": "TO",
      "label": "🇹🇴 +676 - Tonga",
      "label2": "🇹🇴 +676"
    },
    {
      "id": 185,
      "code": "TN",
      "flag": "🇹🇳",
      "indicative": 216,
      "countryName": "Tunisia",
      "value": "TN",
      "label": "🇹🇳 +216 - Tunisia",
      "label2": "🇹🇳 +216"
    },
    {
      "id": 186,
      "code": "TR",
      "flag": "🇹🇷",
      "indicative": 90,
      "countryName": "Turkey",
      "value": "TR",
      "label": "🇹🇷 +90 - Turkey",
      "label2": "🇹🇷 +90"
    },
    {
      "id": 187,
      "code": "TM",
      "flag": "🇹🇲",
      "indicative": 7370,
      "countryName": "Turkmenistan",
      "value": "TM",
      "label": "🇹🇲 +7370 - Turkmenistan",
      "label2": "🇹🇲 +7370"
    },
    {
      "id": 188,
      "code": "TV",
      "flag": "🇹🇻",
      "indicative": 688,
      "countryName": "Tuvalu",
      "value": "TV",
      "label": "🇹🇻 +688 - Tuvalu",
      "label2": "🇹🇻 +688"
    },
    {
      "id": 189,
      "code": "UG",
      "flag": "🇺🇬",
      "indicative": 256,
      "countryName": "Uganda",
      "value": "UG",
      "label": "🇺🇬 +256 - Uganda",
      "label2": "🇺🇬 +256"
    },
    {
      "id": 190,
      "code": "UA",
      "flag": "🇺🇦",
      "indicative": 380,
      "countryName": "Ukraine",
      "value": "UA",
      "label": "🇺🇦 +380 - Ukraine",
      "label2": "🇺🇦 +380"
    },
    {
      "id": 191,
      "code": "AE",
      "flag": "🇦🇪",
      "indicative": 971,
      "countryName": "United Arab Emirates",
      "value": "AE",
      "label": "🇦🇪 +971 - United Arab Emirates",
      "label2": "🇦🇪 +971"
    },
    {
      "id": 192,
      "code": "GB",
      "flag": "🇬🇧",
      "indicative": 44,
      "countryName": "United Kingdom",
      "value": "GB",
      "label": "🇬🇧 +44 - United Kingdom",
      "label2": "🇬🇧 +44"
    },
    {
      "id": 193,
      "code": "US",
      "flag": "🇺🇸",
      "indicative": 1,
      "countryName": "United States",
      "value": "US",
      "label": "🇺🇸 +1 - United States",
      "label2": "🇺🇸 +1"
    },
    {
      "id": 194,
      "code": "UY",
      "flag": "🇺🇾",
      "indicative": 598,
      "countryName": "Uruguay",
      "value": "UY",
      "label": "🇺🇾 +598 - Uruguay",
      "label2": "🇺🇾 +598"
    },
    {
      "id": 195,
      "code": "UZ",
      "flag": "🇺🇿",
      "indicative": 998,
      "countryName": "Uzbekistan",
      "value": "UZ",
      "label": "🇺🇿 +998 - Uzbekistan",
      "label2": "🇺🇿 +998"
    },
    {
      "id": 196,
      "code": "VU",
      "flag": "🇻🇺",
      "indicative": 678,
      "countryName": "Vanuatu",
      "value": "VU",
      "label": "🇻🇺 +678 - Vanuatu",
      "label2": "🇻🇺 +678"
    },
    {
      "id": 197,
      "code": "VE",
      "flag": "🇻🇪",
      "indicative": 58,
      "countryName": "Venezuela",
      "value": "VE",
      "label": "🇻🇪 +58 - Venezuela",
      "label2": "🇻🇪 +58"
    },
    {
      "id": 198,
      "code": "VN",
      "flag": "🇻🇳",
      "indicative": 84,
      "countryName": "Vietnam",
      "value": "VN",
      "label": "🇻🇳 +84 - Vietnam",
      "label2": "🇻🇳 +84"
    },
    {
      "id": 199,
      "code": "EH",
      "flag": "🇪🇭",
      "indicative": 212,
      "countryName": "Western Sahara",
      "value": "EH",
      "label": "🇪🇭 +212 - Western Sahara",
      "label2": "🇪🇭 +212"
    },
    {
      "id": 200,
      "code": "YE",
      "flag": "🇾🇪",
      "indicative": 967,
      "countryName": "Yemen",
      "value": "YE",
      "label": "🇾🇪 +967 - Yemen",
      "label2": "🇾🇪 +967"
    },
    {
      "id": 201,
      "code": "ZM",
      "flag": "🇿🇲",
      "indicative": 260,
      "countryName": "Zambia",
      "value": "ZM",
      "label": "🇿🇲 +260 - Zambia",
      "label2": "🇿🇲 +260"
    },
    {
      "id": 202,
      "code": "ZW",
      "flag": "🇿🇼",
      "indicative": 26,
      "countryName": "Zimbabwe",
      "value": "ZW",
      "label": "🇿🇼 +26 - Zimbabwe",
      "label2": "🇿🇼 +26"
    }
  ]

export const IMGS = {
  LOGOS: {
    cobruLogo,
    ezyLogo,
    pagaHoyLogo,
    tucanpayLogo
  }
}