import React, { useEffect, useState, useContext } from 'react'
import cobruLogo from '../assets/icons/cobruIconHorizontal.png'
import ezyLogo from '../assets/icons/EzyLogo.jpeg'
import pagaHoyLogo from '../assets/icons/paga-hoy-horizontal.png'
import { MainContext } from '../contexts/MainContext'

const NavBarCobru = () => {

    const { isEzy, isPagaHoy, getServiceStyles } = useContext(MainContext);

    const [showNav, setShowNav] = useState('')

    const handleScroll = () => {
        const scroll = window.scrollY;

        if (scroll > 10) {
            setShowNav("show");
        } else {
            setShowNav("");
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])
    
    return (
        <nav className={showNav}>
            <div className="wrapper">
                <a className="flex justify-center items-center" href="https://cobru.co/">
                    <img src={getServiceStyles()?.logo} />
                </a>
            </div>
        </nav>
    )
}

export default NavBarCobru