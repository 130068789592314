// import React from "react";
// import { MdMail } from "react-icons/md";

// // Función para codificar el correo electrónico visible (protección básica contra bots)
// const encodeEmail = (email) => {
//   return email
//     .split("")
//     .map((char) => `&#${char.charCodeAt(0)};`)
//     .join("");
// };

// // Función para crear el enlace "mailto" codificado
// const createMailto = (email) => {
//   return `mailto:${encodeURIComponent(encodeEmail(email))}`;
// };

// const EmailContact = ({ cobruInfo }) => {
//   // Determina el correo a usar
//   const email =
//     cobruInfo?.owner_email || cobruInfo?.email_customer_service || null;

//   if (!email) {
//     // Si no hay correo, solo renderiza el ícono con un enlace deshabilitado
//     return (
//       <a href="#" aria-disabled="true">
//         <MdMail />
//       </a>
//     );
//   }

//   const encodedEmail = encodeEmail(email); // Codifica para el texto visible
//   const mailtoLink = createMailto(email); // Codifica para el atributo href

//   return (
//     <a href={mailtoLink}>
//       <MdMail />
//       {/* <span dangerouslySetInnerHTML={{ __html: encodedEmail }} />  */}{/* Texto codificado */}
//     </a>
//   );
// };

// // export default EmailContact;
// import React, { useMemo } from 'react';
// import { MdMail } from 'react-icons/md';

// const EmailLink = ({ cobruInfo }) => {
//   // Ofuscar el correo electrónico
//   // Determina el correo a usar
// const email = cobruInfo?.owner_email || cobruInfo?.email_customer_service || null;

//   const mailtoLink = useMemo(() => {
//     if (!email) return '';
//     const encodedEmail = email
//       .split('')
//       .map(char => `&#${char.charCodeAt(0)};`)
//       .join('');
//     return `mailto:${email}`;
//   }, [email]);

//   return (
//     <a href={mailtoLink}>
//       <MdMail />
//       {/* <span dangerouslySetInnerHTML={{ __html: email }} /> */}
//     </a>
//   );
// };

// export default EmailLink;

import { useCallback } from 'react';
import { MdMail } from 'react-icons/md';

const EmailLink = ({ cobruInfo }) => {
  const email = cobruInfo?.owner_email || cobruInfo?.email_customer_service || null;

  // Generar la versión ofuscada del correo
  const obfuscatedEmail = email
    ? `${email.charAt(0)}***@${email.split('@')[1]?.split('.')[0]}***.com`
    : 'No disponible';

  // Manejar el clic en el enlace
  const handleMailClick = useCallback((event) => {
    if (email) {
      event.preventDefault();

      // Si el correo es válido, redirigimos al cliente de correo
      window.location.href = `mailto:${email}`;
    } else {
      event.preventDefault();
      //alert('No email address available');
    }
  }, [email]);

  return (
    <a
      href="#"
      onClick={handleMailClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        background: 'none',
        border: 'none',
        color: 'inherit',
        cursor: email ? 'pointer' : 'not-allowed',
      }}
    >
      <MdMail />
    </a>
  );
};

export default EmailLink;
