// Translate.js
import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const Translate = ({ id }) => {
  const { translate } = useLanguage();
  return <>{translate(id)}</>;
};

export default Translate;
