import React, { Fragment, useState } from 'react'
import Modal from './Modal'
import ListItem from './ListItem'
import { TiArrowSortedDown } from "react-icons/ti"
const SelectModal = ({ items = [], selectedItem = {}, setSelectItem = () => { }, customLabelKey = '' }) => {
    const [showModal, setShowModal] = useState(false)
    return (
        <>
            <button onClick={() => setShowModal(true)} className="payment-input flex flex-row items-center justify-between bg-white">
                <p>{customLabelKey ? selectedItem[customLabelKey]:  selectedItem?.label}</p>
                <TiArrowSortedDown />
            </button>
            <Modal showModal={showModal} setShowModal={setShowModal}>
                {
                    items && items.length > 0 && items.map((item, i) => {
                        return (
                            <ListItem key={i} title={/* customLabelKey ? item[customLabelKey] :  */item?.label} className={selectedItem.value === item.value && item.value !== '' ? 'bg-primary text-white' : item.value === '' ? '' : ''} onClick={() => {
                                if (item.value !== '') {
                                    setSelectItem(item)
                                    setShowModal(false)

                                }
                            }} />
                        )
                    })
                }
            </Modal>
        </>
    )
}

export default SelectModal