import { useCallback } from 'react';
import { MdPhone } from 'react-icons/md';

const PhoneContact = ({ cobruInfo }) => {
  const phone = cobruInfo?.phone && cobruInfo?.country_code ? `tel:${cobruInfo?.country_code}${cobruInfo?.phone}` : cobruInfo?.phone && cobruInfo?.country_code ? `tel:${cobruInfo?.phone}` : ""

  // Generar la versión ofuscada del phone
  const obfuscatedPhone = phone
    ? `${phone.slice(0, 4)}***-****${phone.slice(-4)}`
    : 'No disponible';


  // Manejar el clic en el enlace
  const handlePhoneClick = useCallback((event) => {
    if (phone) {
      event.preventDefault();

      // Si el phone es válido, redirigimos al cliente de phone
      window.location.href = `tel:${phone}`;
    } else {
      event.preventDefault();
      //alert('No phone available');
    }
  }, [phone]);

  return (
    <a
      href="#"
      onClick={handlePhoneClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        background: 'none',
        border: 'none',
        color: 'inherit',
        cursor: phone ? 'pointer' : 'not-allowed',
      }}
    >
      <MdPhone />
    </a>
  );
};

export default PhoneContact;
