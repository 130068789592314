
import React, { useState, useEffect } from 'react';

const UserNameComponent = ({ cobruInfo, bigBoldText='bigBoldText' }) => {
  const [dynamicClass, setDynamicClass] = useState('');

  useEffect(() => {
    if (!cobruInfo) return; // Evita ejecutar si no hay información

    // Genera un nombre de clase único
    const className = `user-name-${Math.random().toString(36).substr(2, 9)}`;
    setDynamicClass(className);

    // Inyecta los estilos dinámicos al DOM
    const style = document.createElement('style');
    style.textContent = `
      .${className}::after {
        content: "${cobruInfo}";
      }
    `;
    style.className = className; // Asocia la clase al estilo para fácil identificación
    document.head.appendChild(style);

    // Limpia los estilos al desmontar el componente
    return () => {
      const existingStyle = document.querySelector(`style.${className}`);
      if (existingStyle) {
        existingStyle.remove();
      }
    };
  }, [cobruInfo]); // Solo ejecuta cuando cambia cobruInfo

  return (
    <p className={`${bigBoldText} ${dynamicClass}`}>
      {/* Renderiza el contenido principal */}
    </p>
  );
};

export default UserNameComponent;
