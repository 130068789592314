import React from 'react'
import { formatCOPNumber, formatCOPNumberInput, getDigits, getDigitsRegister } from '../functions'

const Input = ({ value = '', onChange = () => { }, placeholder = '', className = '', errorMessage = '', name = '', onBlur = () => { }, amountSelector = false, options = [], optionValue = null, onChangeValue = () => { }, rate = '', disabled=false, autocomplete='', required=false, pattern='', maxlength=null, type='text'}) => {
    if (amountSelector === true) {
        return (
            <>
                <div className='flex items-center justify-between flex-row payment-input-selector bg-red-100 w-full'>
                    <div className={"flex flex-col w-10/12"}>
                        <input disabled={disabled} value={value} name={name} onBlur={onBlur} className={`outline-none text-3xl font-semibold ${className}`} placeholder={placeholder} onChange={e => onChange(e.target.value)}
                        />
                        

                    </div>
                    <div className=''>
                        { amountSelector &&
                            <select
                                className='outline-none	cursor-pointer'
                                value={optionValue}
                                onChange={(e) => {
                                    onChangeValue(e)
                                }}>
                                {
                                    options && options.length > 0 && options.map((item, i) => {
                                        return (
                                            <option key={i} /* selected={item?.value === optionValue} */ value={item?.value} >{item?.label}</option>
                                        )
                                    })
                                }
                            </select>
                        }
                    </div>
                </div>
                {
                            errorMessage !== ''
                                ?
                                <div className='w-full px-3 mb-3'>
                                    <p className="text-red-600 text-sm text-left">{errorMessage}</p>
                                </div>
                                :
                                null
                        }
                {
                    optionValue === 'USD' && rate && value &&
                    <div className='ml-3 flex flex-row items-center'>
                        <p className='text-gray-400 font-normal text-small min-w-max'>{ value+ ' USD ≈ '+formatCOPNumberInput((getDigitsRegister(value) * parseInt(rate)) ,true)}</p>
                        <p className='text-gray-400 font-normal text-small min-w-max ml-1'>{"COP"}</p>
                    </div>
                }
            </>

        )
    }
    return (
        <>
            <input type={type} maxlength={maxlength} pattern={pattern} required={required} autoComplete={autocomplete} value={value} disabled={disabled} name={name} onBlur={onBlur} className={`payment-input ${errorMessage !== '' ? 'input-error' : ''}${className}`} placeholder={placeholder} onChange={e => onChange(e.target.value)}
            />
            {
                errorMessage !== ''
                    ?
                    <div className='w-full px-3'>
                        <p className="text-red-600 text-sm text-left">{errorMessage}</p>
                    </div>
                    :
                    null
            }
        </>
    )
}

export default Input